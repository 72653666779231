<template>
  <div class="partner">
    <img src="../../../assets/imges/index/6.png" class="headerimg" />
    <!-- 为什么选择 -->
    <div class="whyCheck">
      <div class="title">为什么要成为邦伲德合作伙伴</div>
      <div class="whyChecklist">
        <div class="whyCheckcard">
          <div class="whyCheckimg">
            <img src="../../../assets/imges/index/30.png" alt="" />
          </div>
          <div class="whyChecktitle">丰富的市场资源</div>
          <div class="whyCheckinfo">
            联合市场推广、营销加乘市场激励、标杆案例宣传、品牌授权、峰会交流，助力合作伙伴拓展市场
          </div>
        </div>
        <div class="whyCheckcard">
          <div class="whyCheckimg">
            <img src="../../../assets/imges/index/31.png" alt="" />
          </div>
          <div class="whyChecktitle">专业的培训认证</div>
          <div class="whyCheckinfo">
            定制培训课程，提供线上线下解决方案及产品培训、认证培训、专家大咖分享，全面提升合作伙伴业务能力
          </div>
        </div>
        <div class="whyCheckcard">
          <div class="whyCheckimg">
            <img src="../../../assets/imges/index/32.png" alt="" />
          </div>
          <div class="whyChecktitle">领先的产品技术</div>
          <div class="whyCheckinfo">
            独特的云端能力，拥有覆盖全行业的自研产品、联合生态产品，用户验证，稳定可靠
          </div>
        </div>
        <div class="whyCheckcard">
          <div class="whyCheckimg">
            <img src="../../../assets/imges/index/33.png" alt="" />
          </div>
          <div class="whyChecktitle">强大的邦伲德生态</div>
          <div class="whyCheckinfo">
            开放邦伲德生态资源，融合连接力量，与合作伙伴互联共赢，共建智慧产业新生态
          </div>
        </div>
      </div>
    </div>

    <div class="partner">
      <div class="title">合伙人政策</div>
      <div class="partnerlist">
        <div class="partnercard">
          <div class="partnerimg">
            <img src="../../../assets/imges/index/34.png" alt="" />
          </div>
          <div class="partnertitle">一次选择、终生事业</div>
          <div class="partnerinfo">
            市场推广初期，开放合伙人，用户基数上来将不再招募合伙人。早加入的合伙人有机会和公司一起获得未来上市带来的红利。
          </div>
        </div>
        <div class="partnercard">
          <div class="partnerimg">
            <img src="../../../assets/imges/index/35.png" alt="" />
          </div>
          <div class="partnertitle">区域保护、永久收益</div>
          <div class="partnerinfo">
            区域保护政策，让合伙人可以和平台长期绑定发展。
          </div>
        </div>
        <div class="partnercard">
          <div class="partnerimg">
            <img src="../../../assets/imges/index/36.png" alt="" />
          </div>
          <div class="partnertitle">功能模块生态化</div>
          <div class="partnerinfo">
            功能模块生态化，原属邦伲德的技术团队不断优化和更新APP产品，合伙人不用频繁寻找新产品。
          </div>
        </div>
      </div>

      <div class="partnerlist">
        <div class="partnercard">
          <div class="partnerimg">
            <img src="../../../assets/imges/index/37.png" alt="" />
          </div>
          <div class="partnertitle">通道丰富、便捷推广</div>
          <div class="partnerinfo">
            通道丰富、稳定，保证用户体验，产品用户粘性高，合伙人推广成本低。
          </div>
        </div>
        <div class="partnercard">
          <div class="partnerimg">
            <img src="../../../assets/imges/index/38.png" alt="" />
          </div>
          <div class="partnertitle">自我裂变、用户激增</div>
          <div class="partnerinfo">
            APP的合伙人制度，用户有自我分裂基因，用户基数大发展快。
          </div>
        </div>
        <div class="partnercard">
          <div class="partnerimg">
            <img src="../../../assets/imges/index/39.png" alt="" />
          </div>
          <div class="partnertitle">保姆式运营保障</div>
          <div class="partnerinfo">
            公司保姆式运营团队，时刻保障合伙人的业务操作，让合伙人省心省力赚钱。
          </div>
        </div>
      </div>
    </div>

    <div class="serve">
      <div class="title">服务商扶持计划</div>
      <div class="servelist">
        <div class="serveimg">
          <img src="../../../assets/imges/index/40.png" alt="" />
        </div>
        <div class="servecard">
          <div class="serveinfo">
            <div class="serveinfoimg">
              <img src="../../../assets/imges/index/41.png" alt="" />
            </div>
            <div class="servetitle">高返点</div>
            <div class="servetext">
              总部提供高额返点优惠辅助服务商更好的发展公司，储备人才，拓展当地市场。
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="support">
      <div class="title">六大资源支持</div>
      <div class="supportlist">
        <div class="supportcard">
          <div class="block">1</div>
          <div class="supportinfo">
            <div class="supporttitle">信息系统</div>
            <div class="supporttext">共享客户与运营管理系统</div>
          </div>
        </div>
        <div class="supportcard">
          <div class="block">2</div>
          <div class="supportinfo">
            <div class="supporttitle">用户资源</div>
            <div class="supporttext">共享用户资源</div>
          </div>
        </div>
        <div class="supportcard">
          <div class="block">3</div>
          <div class="supportinfo">
            <div class="supporttitle">业务培训</div>
            <div class="supporttext">专业化的员工培训及业务支持</div>
          </div>
        </div>
      </div>
      <div class="supportlist">
        <div class="supportcard">
          <div class="block">4</div>
          <div class="supportinfo">
            <div class="supporttitle">媒体支持</div>
            <div class="supporttext">中国新商业媒体，提供广阔的宣发渠道</div>
          </div>
        </div>
        <div class="supportcard">
          <div class="block">5</div>
          <div class="supportinfo">
            <div class="supporttitle">品牌授权</div>
            <div class="supporttext">品牌赋能，快速提升合伙人地方影响力</div>
          </div>
        </div>
        <div class="supportcard">
          <div class="block">6</div>
          <div class="supportinfo">
            <div class="supporttitle">投融资支持</div>
            <div class="supporttext">
              1.8万投资人，专业团队把关，帮助地方项目精确匹配资本
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="cooperation">
      <div class="title">优秀合作商</div>
      <div class="cooperationimg">
        <img src="../../../assets/imges/index/42.png" alt="" />
      </div>
    </div>

    <div class="value">
      <div class="title">充分享受 合作价值</div>
      <div class="valuelist">
        <div class="valuecard">
          <div class="valuetitle">提升客单价</div>
          <div class="valueinfo">商派品牌背书</div>
          <div class="valuetext">更多案例佐证</div>
        </div>
        <div class="valuecard">
          <div class="valuetitle">提升营销能力</div>
          <div class="valueinfo">更多成熟方案</div>
          <div class="valuetext">可复制的营销模式</div>
        </div>
        <div class="valuecard">
          <div class="valuetitle">更大利润空间</div>
          <div class="valueinfo">交付周期更短</div>
          <div class="valuetext">成本更低</div>
        </div>
        <div class="valuecard">
          <div class="valuetitle">共同成长</div>
          <div class="valueinfo">全国伙伴经验互通共享</div>
          <div class="valuetext">在合作竞争中成长</div>
        </div>
      </div>
    </div>

    <div class="word">
      <div class="title">世界很大 找你不容易</div>
      <div class="title2">
        我们希望通过互联网嫁接，模式复制，品牌授权，资源共享，通过合作共赢影响更多的人！
      </div>
      <div class="wordtitle">邦伲德诚邀您的加入</div>
      <div class="wordimg">
        <img src="../../../assets/imges/index/43.png" alt="" />
      </div>
    </div>

    <div class="recruit">
      <div class="title" style="color: #fff">招募条件</div>
      <div class="recruitlist">
        <div class="recruitcard">
          <div class="recruitimg">
            <img src="../../../assets/imges/index/44.png" alt="" />
          </div>
          <div class="recruittext">不限城市和地区</div>
        </div>
        <div class="recruitcard">
          <div class="recruitimg">
            <img src="../../../assets/imges/index/45.png" alt="" />
          </div>
          <div class="recruittext">愿意扎根互联网行业</div>
        </div>
        <div class="recruitcard">
          <div class="recruitimg">
            <img src="../../../assets/imges/index/46.png" alt="" />
          </div>
          <div class="recruittext">有丰富本地资源</div>
        </div>
        <div class="recruitcard">
          <div class="recruitimg">
            <img src="../../../assets/imges/index/47.png" alt="" />
          </div>
          <div class="recruittext">浓厚创业激情，喜欢挑战</div>
        </div>
        <div class="recruitcard">
          <div class="recruitimg">
            <img src="../../../assets/imges/index/48.png" alt="" />
          </div>
          <div class="recruittext">责任心强，愿意共同成长</div>
        </div>
      </div>
    </div>

    <div class="join">
      <div class="title">加入我们</div>
    </div>

    <!--  Join Us -->
    <div class="joinUs">
      <div class="innerUs">
        <div class="leftImg">
          <img
            class="leftImg2"
            src="../../../assets/imges/index/19.png"
            alt=""
          />
        </div>
        <div class="rightInfo">
          <div class="first">Join Us</div>
          <div class="second">加盟合作</div>
          <div class="three">
            欢迎您来到邦伲德，我们将竭诚为您提供最好的服务，让您得到更好的收获，您可以提交您的信息，更深入的了解加盟合作信息。
          </div>
          <!-- 加盟表单 -->
          <div class="infoForm">
            <div class="leftName">
              <div class="userName">姓名</div>
              <div class="userName">
                <el-input class="nameIput" v-model="form.name"></el-input>
              </div>
            </div>
            <div class="rightName">
              <div class="userName">手机号</div>
              <div class="userName">
                <el-input class="nameIput" v-model="form.mobile"></el-input>
              </div>
            </div>
          </div>
          <!-- 加盟内容 -->
          <div class="content">
            <div class="topContent">加盟内容</div>
            <div class="textArea">
              <el-input
                type="textarea"
                class="innerArea"
                v-model="form.joinContent"
                resize="none"
              ></el-input>
            </div>
          </div>
          <el-button
            class="sureBtn"
            size="medium"
            type="primary"
            @click="JoinInAdd"
            >确认提交</el-button
          >
          <!-- <img class="sureBtn" src="../../../assets/imges/index/20.png" alt=""> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { JoinInAdd } from "@/api/index.js";
export default {
  data() {
    return {
      form: {
        name: "",
        mobile: "",
        joinContent: "",
      },
    };
  },
  methods: {
    JoinInAdd() {
      let { name, mobile, joinContent } = this.form;
      if (!name || !mobile || !joinContent) {
        this.$message.error("请完善加盟信息");
      } else {
        JoinInAdd({
          name,
          mobile,
          JoinInContent: joinContent,
        }).then((res) => {
          if (res.code == 204) {
            this.form = {};
            this.$message.success("感谢留言，我们会尽快与您联系");
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.join {
  margin-top: 140px;
  margin-bottom: 60px;
}

.recruit {
  background-image: url("../../../assets/imges/index/49.png");
  height: 480px;
  padding: 90px 0px;
  box-sizing: border-box;
  background-size: 100% 100%;
  .recruitlist {
    margin: auto;
    margin-top: 60px;
    display: flex;
    width: 80%;
    justify-content: space-between;
    .recruitcard {
      text-align: center;
      width: 259px;
      height: 190px;
      background: #ffffff;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
      .recruitimg {
        margin-top: 44px;
        img {
          width: 60px;
        }
      }
      .recruittext {
        margin-top: 19px;
        font-size: 14px;
        font-weight: 500;
        color: #616b80;
        line-height: 20px;
      }
    }
  }
}

.word {
  text-align: center;
  margin: 140px auto;
  .wordtitle {
    margin-top: 60px;
    font-size: 24px;
    font-weight: 500;
    color: #333333;
    line-height: 33px;
  }
  .wordimg {
    margin-top: 33px;
    img {
      width: 1402px;
    }
  }
}

.value {
  padding: 90px 0px;
  background: #f5f7fa;
  .valuelist {
    margin: auto;
    margin-top: 60px;
    display: flex;
    width: 80%;
    justify-content: space-between;
    .valuecard {
      width: 325px;
      height: 186px;
      background: #ffffff;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
      border-radius: 14px 14px 0px 0px;
      text-align: center;
      .valuetitle {
        color: #fff;
        width: 326px;
        height: 82px;
        line-height: 82px;
        font-size: 24px;
        box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
        border-radius: 14px 14px 0px 0px;
        background: #2f7ffc;
        border-radius: 14px 14px 0px 0px;
      }
      .valueinfo {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 21px;
        margin-top: 30px;
      }
      .valuetext {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #616b80;
        line-height: 20px;
      }
    }
  }
}

.cooperation {
  margin: 140px auto;
  .cooperationimg {
    margin-top: 58px;
    text-align: center;
    img {
      width: 1400px;
    }
  }
}

.support {
  .supportlist {
    margin: auto;
    margin-top: 60px;
    display: flex;
    width: 80%;
    justify-content: space-between;
    .supportcard {
      .supportinfo {
        display: inline-block;
        min-width: 263px;
        max-width: 266px;
        .supporttitle {
          font-size: 24px;
          font-weight: 500;
          color: #333333;
          line-height: 36px;
        }
        .supporttext {
          margin-top: 20px;
          font-size: 14px;
          font-weight: 500;
          color: #616b80;
          line-height: 20px;
        }
      }
    }
  }
}

.serve {
  margin: 140px auto;
  .servelist {
    margin: auto;
    margin-top: 60px;
    display: flex;
    width: 80%;
    justify-content: center;
    .serveimg {
      img {
        width: 826px;
      }
    }
    .servecard {
      width: 574px;
      background: #f5f7fa;
      .serveinfo {
        margin-left: 112px;
        .serveinfoimg {
          margin-top: 90px;
          img {
            width: 54px;
          }
        }
        .servetitle {
          margin-top: 40px;
          margin-bottom: 20px;
          font-size: 30px;
          font-weight: 500;
          color: #333333;
          line-height: 45px;
        }
        .servetext {
          font-size: 24px;
          font-weight: 400;
          color: #616b80;
          line-height: 33px;
        }
      }
    }
  }
}

.partner {
  .partnerlist {
    margin: auto;
    margin-top: 60px;
    display: flex;
    width: 80%;
    justify-content: space-between;
    .partnercard {
      width: 397px;
      height: 357px;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
      .partnerimg {
        text-align: center;
        margin-top: 60px;
        img {
          width: 90px;
        }
      }
      .partnertitle {
        margin-top: 40px;
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        color: #333333;
        line-height: 36px;
      }
      .partnerinfo {
        margin: 0px 47px;
        margin-top: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #616b80;
        line-height: 20px;
      }
    }
  }
}

.whyCheck {
  margin: 140px 0px;
  .whyChecklist {
    margin: auto;
    margin-top: 60px;
    display: flex;
    width: 80%;
    justify-content: space-between;
    .whyCheckcard {
      width: 325px;
      height: 335px;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
      .whyCheckimg {
        margin-top: 61px;
        text-align: center;
        img {
          width: 70px;
        }
      }
      .whyChecktitle {
        margin-top: 40px;
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        color: #333333;
        line-height: 36px;
      }
      .whyCheckinfo {
        margin: 0px 18px;
        margin-top: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #616b80;
        line-height: 20px;
      }
    }
  }
}
// Join Us
.joinUs {
  padding: 150px 0;
  background: #f5f5f9;
}
.innerUs {
  width: 70%;
  margin-left: 15%;
  display: flex;
  justify-content: space-between;
}
.rightInfo {
  font-family: Medium;
  padding-left: 100px;
  position: relative;
}
.first {
  font-size: 34px;
  font-weight: 600;
  color: #060606;
  line-height: 48px;
  border-left: 4px solid #2f7ffc;
  padding-left: 30px;
}
.second {
  letter-spacing: 1px;
  font-size: 20px;
  font-weight: 600;
  color: #858585;
  line-height: 28px;
  border-left: 4px solid #ffda0a;
  padding: 10px 0 10px 30px;
}
.three {
  width: 749px;
  padding-top: 30px;
  font-size: 18px;
  font-weight: 500;
  color: #787878;
  line-height: 32px;
}
// 表单
.infoForm {
  margin-top: 30px;
  display: flex;
  font-size: 18px;
  font-family: Medium;
  font-weight: 500;
  color: #787878;
  line-height: 22px;
}
.userName {
  padding: 10px 0;
}
.leftName {
  width: 50%;
}
.rightName {
  width: 50%;
}
.nameIput {
  width: 314px;
}
.content {
  margin-top: 60px;
  font-size: 18px;
  font-family: Medium;
  font-weight: 500;
  color: #787878;
  line-height: 22px;
}
.topContent {
  padding: 10px 0 25px 0;
}
.innerArea {
  height: 254px;
}
/deep/.el-textarea__inner {
  height: 254px;
}

.sureBtn {
  position: absolute;
  right: 50px;
  bottom: 0;
  background: linear-gradient(270deg, #0a89ff 0%, #4371ff 100%);
}

.block {
  width: 44px;
  height: 44px;
  background: #2f7ffc;
  display: inline-block;
  margin-right: 20px;
  line-height: 10px;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  box-sizing: border-box;
  padding: 17px 6px;
  vertical-align: top;
  text-align: center;
}
</style>